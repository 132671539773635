.detail-card {
  padding: 1rem;
  height: 35vh;
  border-radius: 12px;
  box-shadow: 0 2px 6px 0 rgb(0 0 0 / 16%);
  background-color: transparent;
  transition: opacity 2s ease-out;
  transition: height 2s ease-out;
  overflow: hidden;
  display: flex;
}

.detail-card:hover {
  box-shadow: 15px 15px 10px -10px rgba(31, 31, 31, 0.5);
  height: 40vh;
}

.detail-card-head {
  margin-top: 2rem;
  transition: margin-top 2s ease;
}

.detail-card:hover .detail-card-head {
  margin-top: 0;
}

.detail-card-text {
  font-size: 16px;
  text-align: justify;
  color: #000000 !important;
  transition: all 2s ease;
  opacity: 0;
}

.detail-card:hover .detail-card-text {
  font-size: 16px;
  text-align: justify;
  opacity: 1;
}
