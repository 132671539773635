/*  GLOBAL RESET */
* {
  box-sizing: border-box;
}
body {
  font-family: "Ubuntu", sans-serif;
  overflow-x: hidden;
  width: 100%;
}

/* GLOBAL CSS START */

/* space wrap css */
.space-wrap {
  white-space: nowrap !important;
}

/* setting padding value zero */
.reset-padding {
  padding: 0px !important;
}

/* padding  */
.p-0 {
  padding: 0 !important;
}

.p-0-5-rem {
  padding: 0 0.5rem !important;
}

.p-1 {
  padding: 1% !important;
}
.pr-0-25 {
  padding-right: 0.25% !important;
}

.pr-0-5 {
  padding-right: 0.5% !important;
}

.pr-1 {
  padding-right: 1% !important;
}

.pr-8 {
  padding-right: 8% !important;
}

/* padding bottom global css */
.pb-0 {
  padding-bottom: 0 !important;
}

.pb-0-5-rem {
  padding-bottom: 0.5rem !important;
}

.pb-1 {
  padding-bottom: 1% !important;
}

.pb-2 {
  padding-bottom: 2% !important;
}

/* padding left global css */
.pl-0-5-rem {
  padding-left: 0.5rem !important;
}

.pl-1-rem {
  padding-left: 1rem !important;
}

.pl-0-25 {
  padding-left: 0.25% !important;
}

.pl-0-5 {
  padding-left: 0.5% !important;
}

.pl-1 {
  padding-left: 1% !important;
}

.p-top-full-col {
  padding-top: 30px !important;
}

.p-top-col {
  padding-top: 15px !important;
}

.p-bottom-full-col {
  padding-bottom: 30px !important;
}

.p-bottom-col {
  padding-bottom: 15px !important;
}

/* margin */
.m-0 {
  margin: 0 !important;
}

.mr-0 {
  margin-right: 0 !important;
}

.mr-0-25 {
  margin-right: 0.25% !important;
}

.mr-0-5 {
  margin-right: 0.5% !important;
}

.mr-0-37 {
  margin-right: 0.37% !important;
}

.mr-0-75 {
  margin-right: 0.75% !important;
}

.mr-1 {
  margin-right: 1% !important;
}

.mb-1 {
  margin-bottom: 1% !important;
}

.mb-2 {
  margin-bottom: 2% !important;
}

.mb-15 {
  margin-bottom: 15% !important;
}

.ml-0-25 {
  margin-left: 0.25% !important;
}

.ml-0-5 {
  margin-left: 0.5% !important;
}

.ml-0-37 {
  margin-left: 0.37% !important;
}

.ml-0-75 {
  margin-left: 0.75% !important;
}

.ml-1 {
  margin-left: 1% !important;
}

.ml-24 {
  margin-left: 22.2% !important;
}

/* css for the home container */
.home-card {
  padding: 1rem;
  height: 35vh;
  border-radius: 12px;
  box-shadow: 0 2px 6px 0 rgb(0 0 0 / 16%);
  background-color: transparent;
  transition: opacity 2s ease-out;
  transition: height 2s ease-out;
  overflow: hidden;
}

.product-home-card {
  padding: 1rem;
  height: 45vh;
  border-radius: 12px;
  box-shadow: 0 2px 6px 0 rgb(0 0 0 / 16%);
  background-color: transparent;
  transition: opacity 2s ease-out;
  transition: height 2s ease-out;
  overflow: hidden;
}

.home-card:hover {
  box-shadow: 15px 15px 10px -10px rgba(31, 31, 31, 0.5);
  height: 35vh;
}

.product-home-card:hover {
  box-shadow: 15px 15px 10px -10px rgba(31, 31, 31, 0.5);
  height: 50vh;
}

.home-card-icon-container {
  margin-top: 2rem;
  transition: margin-top 2s ease-out;
}

.home-card:hover .home-card-icon-container {
  margin-top: 0;
}

.product-home-card:hover .home-card-icon-container {
  margin-top: 0;
}

.home-card-img {
  padding: 1%;
  height: 10vh;
  border-radius: 50%;
  margin-bottom: 1%;
}

.home-card-img-bg {
  background: #4e5c99;
}

.home-card-img-no-radius {
  border-radius: 0 !important;
}

.home-card-head {
  margin-top: 2rem;
}

.home-card:hover .home-card-head {
  margin-top: 0;
}

.product-home-card:hover .home-card-head {
  margin-top: 0;
}

.home-card-visible-text {
  display: inline-block !important;
  text-align: justify !important;
}

.home-card:hover .home-card-visible-text {
  display: inline-block !important;
  text-align: justify !important;
}

.product-home-card:hover .home-card-visible-text {
  display: inline-block !important;
  text-align: justify !important;
}

.home-card-text {
  font-size: 16px;
  color: #000000 !important;
  transition: all 2s ease;
  opacity: 0;
}

.product-home-card-text {
  font-size: 16px;
  color: #000000 !important;
  transition: all 2s ease;
  display: none;
  opacity: 0;
}

.home-card:hover .home-card-text {
  font-size: 16px;
  opacity: 1;
}

.product-home-card:hover .product-home-card-text {
  font-size: 16px;
  opacity: 1;
  display: inline-block;
}

.home-card-span-div {
  text-align: center;
  transition: all 2s ease;
}

.home-card:hover .home-card-span-div {
  text-align: justify;
}

.product-home-card:hover .home-card-span-div {
  text-align: justify;
}

.home-card-know-more-container {
  opacity: 0;
  transition: all 2s ease-out;
}

.home-card:hover .home-card-know-more-container {
  opacity: 1;
}

.product-home-card:hover .home-card-know-more-container {
  opacity: 1;
}

/* mobile view display resolution */
@media only screen and (max-width: 600px) {
  .home-card {
    height: auto;
  }

  .home-card-visible-text {
    display: none;
  }

  .home-card-text {
    opacity: 1;
    height: auto;
  }
}

.grid-first-col {
  padding: 30px 15px 30px 0 !important;
  max-width: 33.5% !important;
}

.grid-second-col {
  padding: 30px 15px 30px 15px !important;
  max-width: 33.5% !important;
}

.grid-third-col {
  padding: 30px 0 30px 15px !important;
  max-width: 33.5% !important;
}

.know-more-above-div {
  min-height: 10% !important;
}

.details-flex-main-div {
  display: flex;
  margin-bottom: 2%;
}

.details-flex-div-one {
  flex-basis: 11%;
  font-size: 1rem;
  font-weight: bold;
  text-align: start;
}

.details-flex-div-two {
  flex-basis: 5%;
}

.details-flex-div-three {
  flex-basis: 70%;
  font-size: 1rem;
  font-weight: normal;
  text-align: start;
}

/* when modal open it not disappear the main scroll bar  */
.modal-open {
  padding: 0 !important;
  overflow-y: auto;
}

/* GLOBAL CSS END */

/*  NAVBAR STYLES */

#navigation {
  padding: 0;
  z-index: 10;
  background: #fff !important;
  font-family: "Ubuntu", sans-serif;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  height: 80px;
  transition: top 0.3s;
}

.navbar-container {
  max-width: 100% !important;
  padding: 0 !important;
  margin: 0 8.33% !important;
}

#navigation .navbar-nav .navs > a {
  color: #04044c;
}

#navigation .navbar-nav .navs {
  margin: 0 10%;
}

@media (max-width: 1310px) {
  #navigation .navbar-nav .navs {
    margin: 0 5%;
    transition: ease-in-out;
    transition-duration: 0.2ms;
  }
}

@media (max-width: 1100px) {
  #navigation .navbar-nav .navs {
    margin: 0 2%;
    transition: ease-in-out;
    transition-duration: 0.2ms;
  }
}

#navigation .navbar-nav .nav-item > a {
  color: #058026;
  cursor: pointer;
  font-size: 18px;
  padding-right: 0;
  padding-left: 0;
  padding-top: 0;
  padding-bottom: 0;
}

#navigation .navbar-nav > li > a:hover,
#navigation .navbar-nav > li > a:focus {
  color: #04044c;
}
.navs .nav-link {
  color: #04044c;
}
.nav-icon {
  padding: 15%;
}

.active {
  color: #000000 !important;
  border-bottom: 2px solid #000000;
}

.navbar-dropdown-item {
  padding: 0.125rem !important;
}

.dropdown-active {
  color: #000000 !important;
}

.popover-navlink:hover {
  color: #000000 !important;
}

.navbar-cancel-btn {
  font-size: 2rem;
}

.navbar-collapse-div {
  background-color: #fff;
  padding: 2%;
  text-align: left;
  display: flex;
  flex-direction: column;
  overflow: auto;
  max-height: 85vh;
}

.navbar-sign-in-btn {
  text-transform: none !important;
  background-color: #058026 !important;
  font-family: "Ubuntu", sans-serif !important;
  font-size: 1.125rem !important;
  font-weight: 400 !important;
  line-height: 1.5 !important;
  border-radius: 1rem !important;
}

.dropdown-menu {
  left: 3rem !important;
}

/* @media only screen and (min-width: 990px) {
  .dropdown-menu {
    margin-top: 2rem;
  }

  .dropdown:hover .dropdown-menu {
    display: block;
  }

  .dropdown-toggle::after {
    display: none;
  }
} */

.dropdown-toggle::after {
  display: none;
}

.dropdown-menu {
  padding: 0.5rem 0.5rem 0.5rem 0.5rem !important;
  min-width: 0 !important;
}

.nav-item:hover {
  color: #000 !important;
}

.dropdown-item.active,
.dropdown-item:active {
  outline: none;
  text-decoration: none;
  background-color: #fff;
}

@media (max-width: 960px) {
  .navbar-signin-item {
    padding: 0.5rem 0 !important;
  }
  #navigation .navbar-nav .nav-item > a:hover {
    color: #000 !important;
  }
}

/*  CAROUSEL STYLES */

#carousel {
  padding: 2% 8.33% 0 8.33%;
  margin: 0;
  height: 100vh;
}
.carousel-image {
  z-index: 0;
  height: 100vh;
  width: 100%;
}
.carousel-text-box {
  position: absolute;
  top: 70px;
  left: 0;
  width: 80vw;
}
.carousel-text-box-two {
  position: absolute;
  top: 40%;
  width: 77.3vw;
  left: 11%;
}
.carousel-text {
  font-family: "Ubuntu", sans-serif;
  color: #04044c;
  position: relative;
  width: 100%;
  margin-left: 0;
  -webkit-text-stroke: 1px white;
  font-size: 3rem !important;
}
.carousel-sub-text {
  color: #0054f3;
  font-family: "Ubuntu", sans-serif;
  font-style: oblique;
  font-size: 1.25rem;
}
.carousel-right,
.carousel-left {
  position: absolute;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  filter: blur(1px);
  font-size: 2rem;
}
.carousel-left {
  top: 50%;
  left: 8.33%;
  color: #ccc;
  transition: color, transform;
  transition-duration: 1s;
}
.carousel-right {
  position: absolute;
  top: 50%;
  right: 8.33%;
  color: #ccc;
  transition: color, transform;
  transition-duration: 1s;
}
.carousel-left:hover,
.carousel-right:hover {
  color: #fff;
  text-shadow: 0 0 5px #fff;
  box-shadow: 0 0 10px #fff;
  filter: blur(0);
  transform: scale(1.2);
}
.background-overlay {
  background: rgba(0, 0, 0, 0.3);
  padding: 0;
}

.carousel-item-div {
  border-bottom: none;
}

.carousel {
  height: 100% !important;
  display: flex;
  align-items: center;
}

.carousel-indicators {
  z-index: 1 !important;
}

.carousel-indicators li {
  background-color: #000000 !important;
}

.carousel-control-next {
  display: none !important;
}

.carousel-control-prev {
  display: none !important;
}

.carousel-inner .carousel-item {
  transition: -webkit-transform 1.5s ease;
  transition: transform 1.5s ease;
  transition: transform 1.5s ease, -webkit-transform 2s ease;
}

/* mobile view resolution css */
@media only screen and (max-width: 640px) {
  #carousel {
    padding: 9% 8.33% 0 8.33%;
    height: 260vh;
  }
}

/*  INDEX PAGE */

.carousel-container {
  margin: 0;
  padding: 4px;
}
.carousel-card {
  margin-top: -4%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.card-content {
  font-size: 14px;
}
.partner-row {
  display: flex;
  text-align: center;
  margin-top: 0;
  justify-content: center;
  background-color: #fff;
}
.partner-item {
  width: 20vw;
  height: 22vh;
  font-family: "Ubuntu", sans-serif;
  background: #fff;
  padding: 3% 0;
  text-decoration: none;
  box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.2),
    2px 2px 2px 2px rgba(0, 0, 0, 0.19);
  transition: transform, box-shadow, border, background;
  transition-duration: 1s;
}
.partner-item:hover {
  z-index: 2;
  position: relative;
  transform: scale(1.1);
  background: #ddd;
  box-shadow: 0 0px 50px rgba(0, 0, 0, 0.9);
}
.carousel-head {
  color: #0756a3;
  margin-top: -5%;
  margin-bottom: 5%;
}
.partner-image {
  padding: 3%;
  margin-bottom: 10px;
}
.services-icon {
  color: #0756a3;
  font-size: 3rem;
  transform: rotate(0);
  animation: rotate linear infinite 5000ms;
}
@keyframes rotate {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}

/*  SOCIAL ICONS  */

.social {
  position: fixed;
  z-index: 5;
  top: 50%;
  margin: 0;
  transform: translate(-15%, -50%);
  background: rgba(0, 0, 0, 0);
}
.social-list {
  list-style: none;
  background: transparent;
  padding: 15%;
}
.fa {
  color: #fff;
  padding: 10px;
  border-radius: 50%;
  transition: transform;
  transition-duration: 1s;
  margin: 1px 0;
}
.fa-facebook,
.fa-linkedin,
.fa-twitter {
  background: #0054f3;
}
.fa-facebook {
  padding-right: 13px;
  padding-left: 13px;
}
.fa-pinterest,
.fa-linkedin {
  padding-right: 11px;
  padding-left: 11px;
}
.fa:hover {
  transform: rotate(380deg);
  color: #fff;
}
.fa-pinterest,
.fa-youtube-play {
  background: #bf2037;
}

/*  ABOUT SECTION */
#about {
  padding: 7.5% 8.33% 2% 8.33%;
  min-height: 100vh;
}

.about-text-container {
  padding-top: 1%;
}
.about-img-container {
  padding-top: 1%;
}
.about-text-container > h2 {
  padding: 2% 0;
  font-size: 32px;
  font-weight: bold;
}
.about-text-container > h5 {
  line-height: 2;
  padding: 0 5% 0 0;
  font-size: 16px;
}

.container-demo {
  margin: 0;
  padding: 0;
  height: 35vh;
}

/* mobile resolution */
@media only screen and (max-width: 600px) {
  #about {
    padding: 25% 8.33% 2% 8.33%;
  }
  .about-text-container,
  .about-img-container {
    padding-left: 0;
    padding-right: 0;
  }

  .grid-first-col,
  .prod-first-col,
  .services-first-col,
  .partners-first-col,
  .careers-first-col {
    max-width: 100% !important;
    padding: 30px 0 15px 0 !important;
  }
  .grid-second-col,
  .prod-second-col,
  .services-second-col,
  .services-third-col,
  .services-second-row-first-col,
  .services-second-row-second-col {
    max-width: 100% !important;
    padding: 15px 0 15px 0 !important;
  }
  .grid-third-col,
  .prod-third-col,
  .services-second-row-third-col,
  .partners-second-col,
  .careers-second-col {
    max-width: 100% !important;
    padding: 15px 0 30px 0 !important;
  }
}

/*  SERVICE SECTION  */
#services {
  padding: 7.5% 8.33% 2% 8.33%;
  position: relative;
  overflow: hidden;
  min-height: 100vh;
}

.service-img {
  padding: 1%;
  height: 10vh;
  background: #4e5c99;
  border-radius: 50%;
  margin-bottom: 1%;
}
.service-text {
  font-size: 14px;
}

/* mobile view resolution */
@media only screen and (max-width: 600px) {
  #services {
    padding: 25% 8.33% 2% 8.33%;
  }
}

/*  PRODUCTS SECTIONS */
#products {
  padding: 7.5% 8.33% 2% 8.33%;
  position: relative;
  overflow: hidden;
  min-height: 100vh;
}

.prod-row {
  height: 30rem;
  display: flex;
  align-items: center;
}

.differ-header {
  padding-top: 2%;
  font-size: 32px;
}
.differ {
  padding: 3%;
}
.differ .icon-container {
  padding-bottom: 0;
}
.differ > h6 {
  line-height: 1.5;
}
.differ-button {
  border-style: none;
  border: 2px solid #04044c;
  border-radius: 10px;
  padding: 10px 10px;
  font-size: 14px;
  font-weight: 600;
  color: #04044c !important;
  transition: all 0.5s;
}

.differ-button:hover {
  background: #04044c;
  color: #fff !important;
}

/* mobile view resolution css */
@media only screen and (max-width: 600px) {
  #products {
    padding: 25% 8.33% 2% 8.33%;
  }
  .prod-row {
    height: auto !important;
  }
}

/*  CAREERS SECTION  */
#careers {
  padding: 7.5% 8.33% 2% 8.33%;
  background: #fff;
  min-height: 100vh;
}

.career-card {
  margin-top: 9%;
  position: relative;
  transition: transform, background-size;
  transition-duration: 0.8s;
  color: #fff;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-size: cover;
}
.career-card:hover {
  position: relative;
  z-index: 2;
  transform: scale(1.02);
  background-size: 150%;
  border: solid 2px #b7b0b0;
  box-shadow: 0 3px 50px rgba(0, 0, 0, 0.9);
}

.md-6.element.style {
  padding-top: 15px;
}

.careers-close-btn-wrapper {
  margin-top: -1.5rem;
}

/* mobile view resolution css */
@media only screen and (max-width: 600px) {
  #careers {
    padding: 25% 8.33% 2% 8.33%;
  }

  .careers-card-heading {
    padding-bottom: 15%;
  }

  .searchfield {
    top: 40px !important;
    right: 40px !important;
  }

  .careers-search-icon {
    top: 50px !important;
  }
}

/*  CONTACT SECTION  */
#contact {
  padding: 7.5% 8.33% 2% 8.33%;
  background: #fff;
  min-height: 100vh;
}

.contact-block {
  display: flex;
  justify-content: space-between;
  margin-top: 2%;
  align-items: stretch;
}

.contact-address {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.contact-item {
  display: flex;
  align-items: center;
}

.contact-form {
  background-color: rgba(242, 242, 242, 0.2);
  height: 100%;
  width: 43%;
}
.contact-form > h6 {
  color: #273c75;
  padding: 2% 0;
}
.contact-form > form > .form-block > .row > .col-md-3 {
  color: #04044c;
}
.contact-form > form > .form-block > .row {
  margin: 1% 0;
}
.contact-input {
  background: rgba(242, 242, 242, 0.2);
  border-radius: 3px;
  border: 1px solid #04044c;
  height: 2rem;
}
.contact-input:focus {
  border-bottom: 2px solid #1abc9c;
}
.contact-button {
  background: #f2f2f2;
  font-size: 14px;
  color: #04044c;
  border: 1px solid #04044c;
  padding: 2% 15%;
  border-radius: 2px;
  transition: all 0.5s;
}
.contact-button:hover {
  background: #04044c;
  color: #fff;
}

.contact-iframe-div {
  width: 390px;
  height: 300px;
}

.contact-checkbox-div {
  margin: 1% 0 1% 3%;
}

.contact-us-checkbox-label {
  text-align: justify;
}

/* mobile view resolution css */
@media only screen and (max-width: 600px) {
  #contact {
    padding: 25% 8.33% 2% 8.33%;
  }

  .contact-iframe-div {
    width: 310px;
    height: 300px;
  }
}

/*  FOOTER SECTION */

#footer {
  background: #014a03;
  text-align: center;
  color: #f0f0f0;
  padding: 1rem;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.19);
  min-height: 10vh;
}

.footer-copyright {
  margin: 0.7rem 0 0 0 !important;
}

.footer-divider {
  color: rgba(0, 128, 0, 1);
}

.vani-block1 {
  padding: 1% 5%;
}
.vani-block1 > h6 {
  line-height: 1.4;
}
.text-block {
  margin: 2% 0;
}
.text-block > h5 {
  font-weight: bold;
}
.text-block > p {
  font-size: 14px;
  font-weight: 100;
}
.vani-block2 {
  padding: 3% 5%;
  position: relative;
}
.vani-block2 .row .col-md-4 {
  padding: 2% 3%;
}
.vani-block2 .row .col-md-3 h6 {
  line-height: 1.5;
}
.vani-block2 .row .col-md-3 .center {
  height: 58%;
}
.vani-block2 .row .col-md-4 .center h6 {
  color: #04044c;
}
.vani-button {
  display: inline-block;
  padding: 0.5rem 3%;
  background: #00aee4;
  margin-top: 3%;
  color: #fff;
  font-size: 20px;
  transition: all 0.5s;
}
.vani-button:hover {
  text-decoration: none;
  color: #fff;
  transform: scale(1.1);
}

/*  DASHBOARD NAVIGATION  */

.sidebar-toggle-container {
  padding-left: 2%;
}
.sidebar-icon {
  color: #04044c !important;
}
.profile-edit-card {
  margin: 5% 0;
  padding: 5% 0;
  background: #fff;
  border: #ddd 2px solid;
  transition: all 0.8s;
  cursor: pointer;
}
.profile-edit-card i {
  font-size: 2rem;
}
.profile-edit-card:hover {
  background: rgba(9, 132, 227, 0.9);
  color: #fff;
}

textarea:focus,
textarea.form-control:focus,
input.form-control:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="email"]:focus,
input[type="number"]:focus,
[type="text"].form-control:focus,
[type="password"].form-control:focus,
[type="email"].form-control:focus,
[type="tel"].form-control:focus,
[contenteditable].form-control:focus {
  box-shadow: inset 0 0 0 #fff;
  outline: 0 !important;
}
.add-button {
  margin: 0;
  padding: 2px 2%;
  background: #27ae60;
  position: absolute;
  display: inline-block;
  top: 0;
  left: 0;
}

/*  DASHBOARD SIDEBAR  */

.scale {
  margin-left: 14%;
  padding: 0 1%;
  transition: margin-left 0.4s ease-out;
}
.no-scale {
  margin-left: 0;
  transition: all 0.4s ease-out;
}
#no-sidebar {
  min-height: 100%;
  z-index: 8;
  width: 14%;
  margin: 0;
  background: #6b6f9e;
  backface-visibility: hidden;
  position: fixed;
  top: 0;
  left: 0;
  transform: translateX(-100%);
  transition: transform 0.4s ease-out;
}
#sidebar {
  padding-top: 6%;
  top: 0;
  left: 0;
  position: fixed;
  z-index: 8;
  min-height: 100%;
  backface-visibility: hidden;
  width: 14%;
  margin: 0;
  background: #1b2dcf;
  box-shadow: 3px 0 5px rgba(0, 0, 0, 0.5);
  transform: translateX(0);
  transition: transform 0.4s ease-out;
}
.sidebar-block {
  display: flex;
  flex-direction: column;
  padding: 5% 0;
}
.sidebar-main-item {
  padding: 5% 6%;
  border-bottom: 1px solid #6c7e9f;
  margin: 0;
  transition-property: transform, color;
  display: block;
  color: #333;
  transition-duration: 0.5s;
  margin-bottom: 3px;
  position: relative;
}
.sidebar-main-item::before {
  content: "";
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 2px;
  background: #f7f0f0;
  transform: scaleY(0);
  transition: transform 0.1s, width 0.1s cubic-bezier(1, 0, 0, 1);
}
.selected {
  background: #fff !important;
  color: #4b4f7e !important;
}
.sidebar-main-item:hover::before {
  transform: scaleY(1);
  width: 99%;
}
.sidebar-main-item:hover {
  cursor: pointer;
  color: #636e72;
  text-decoration: none;
}
.sidebar-side-items {
  background: #1b2dcf;
  padding-top: 2%;
  border-top: 5px solid #1b2dcf;
}
.sidebar-side-item {
  font-size: 12px;
  font-weight: bold;
  padding-left: 20%;
  margin: -1% 1% 5% 1%;
  border-radius: 100px;
  color: #333;
  position: relative;
}

/*  CALENDAR   */

.icon {
  font-family: "Material Icons", serif;
  font-style: normal;
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-feature-settings: "liga";
}

/* CALENDAR VARIABLES */

:root {
  --main-color: #1a8fff;
  --text-color: #777;
  --text-color-light: #ccc;
  --border-color: #eee;
  --bg-color: #f9f9f9;
  --neutral-color: #fff;
}

/* CALENDAR GENERAL */

.leave-icon {
  padding: 0.6rem;
  color: #eee;
  font-weight: 900;
  border-radius: 50%;
  background-image: linear-gradient(orangered, #e74c3c);
  opacity: 1;
  cursor: pointer;
  transition: all 0.3s;
  box-shadow: 0 2px 5px #444;
}
.leave-icon:hover {
  opacity: 0.7;
}
.leave-icon:focus {
  transform: translateY(2px);
}
.details-card {
  display: block;
  padding: 0;
}
.details-card .pointer {
  margin-top: -2rem;
  margin-bottom: 1rem;
}
header #logo .icon {
  padding-right: 0.25em;
}

/* CALENDER GRID */

.row {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}
.row-middle {
  align-items: center;
}
.col {
  flex-grow: 1;
  flex-basis: 0;
  max-width: 100%;
}
.col-start {
  justify-content: flex-start;
  text-align: left;
}
.col-center {
  justify-content: center;
  text-align: center;
}
.col-end {
  justify-content: flex-end;
  text-align: right;
}

/* CALENDER */

.calendar {
  display: block;
  position: relative;
  width: 100%;
  background: var(--neutral-color);
  border: 1px solid var(--border-color);
}
.calendar .header {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 115%;
  padding: 0;
  border-bottom: 1px solid var(--border-color);
}
.calendar .header .icon {
  cursor: pointer;
  transition: 0.15s ease-out;
}
.calendar .header .icon:hover {
  transform: scale(1.75);
  transition: 0.25s ease-out;
  color: var(--main-color);
}
.calendar .header .icon:first-of-type {
  margin-left: 1em;
}
.calendar .header .icon:last-of-type {
  margin-right: 1em;
}
.calendar .days {
  text-transform: uppercase;
  font-weight: 400;
  color: #777;
  font-size: 70%;
  padding: 0.75em 0;
  border-bottom: 1px solid var(--border-color);
}
.calendar .body .cell {
  position: relative;
  height: 4.5em;
  border-right: 1px solid var(--border-color);
  overflow: hidden;
  cursor: pointer;
  background: var(--neutral-color);
  transition: 0.25s ease-out;
}
.calendar .body .cell:hover {
  background: var(--bg-color);
  transition: 0.5s ease-out;
}
.calendar .body .selected,
.calendar .body .selected:hover {
  background: solid transparent;
  background-image: linear-gradient(45deg, #1a8fff 0%, #53cbf1 40%) !important;
  border-image-slice: 1;
  color: var(--neutral-color);
}
.calendar .body .row {
  border-bottom: 1px solid var(--border-color);
}
.calendar .body .row:last-child {
  border-bottom: none;
}
.calendar .body .cell:last-child {
  border-right: none;
}
.calendar .body .cell .number {
  position: absolute;
  font-size: 82.5%;
  line-height: 1;
  top: 0.75em;
  right: 0.75em;
  font-weight: 700;
}
.calendar .body .disabled {
  color: var(--text-color-light);
  pointer-events: none;
}
.calendar .body .cell .bg {
  font-weight: 700;
  line-height: 1;
  color: #0000ff;
  opacity: 0;
  font-size: 6.5em;
  position: absolute;
  top: -0.25em;
  right: -0.04em;
  transition: 0.25s ease-out;
  letter-spacing: -0.07em;
}
.calendar .body .cell:hover .bg,
.calendar .body .selected .bg {
  opacity: 0.05;
  transition: 0.5s ease-in;
}
.calendar .body .col {
  flex-grow: 0;
  flex-basis: calc(100% / 7);
  width: calc(100% / 7);
}

/*  FUNCTIONALITY STYLES  */

.center {
  text-align: center;
}
.right {
  text-align: right;
}
.left {
  text-align: left;
}
.justify {
  text-align: justify;
}
.nav-pad {
  margin-top: 6%;
}
.shadow {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.4), 0 6px 20px 0 rgba(0, 0, 0, 0.39);
}
.overlay {
  position: relative;
  transition: padding;
  transition-duration: 1s;
  z-index: 3;
  padding: 5%;
  background: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.overlay:hover {
  padding: 9%;
}
.bold {
  font-weight: bold;
}
.pointer {
  cursor: pointer;
}
.heading {
  font-family: "Open Sans Condensed", sans-serif;
  font-weight: bold;
}
.no-mar {
  margin: 0 !important;
}
.m--5 {
  margin: -5px;
}
.mt--5 {
  margin-top: -15px;
}
.mr--5 {
  margin-right: -5px;
}
.ml--5 {
  margin-left: -5px;
}
.mb--5 {
  margin-bottom: -5px;
}
.allSides {
  width: 350px;
  height: 200px;
  border: solid 3px #ccc;
  background-color: #ccc;
  box-shadow: 0 0px 50px rgba(0, 0, 0, 0.6);
}
.container-card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.career-row {
  background: #ddd;
  color: #444;
}
.table-row {
  background: #fff;
  color: #333;
}
.table-row:hover {
  background: #ddd;
}
.file-button {
  padding: 3px 10px;
  margin-left: 2%;
  background: #cf2346;
  color: #fff;
  border: #bf2037 2px solid;
  border-radius: 3px;
}
.flex-between {
  display: flex;
  justify-content: space-around;
}
.flex-left {
  display: flex;
  align-items: center;
}
.flex {
  display: flex;
  justify-content: center;
}
.flex-around {
  display: flex;
  justify-content: left;
  margin: 0;
  padding: 0;
  justify-content: space-around;
}
.flex-1 {
  flex: 1;
}

/*  COLOR CLASSES  */

.teal {
  background-color: #1abc9c;
  border: 0;
}
.purple {
  background-color: #273c75;
}
.white-text {
  color: #fff;
}
.black-text {
  color: #333;
}
.purple-text {
  color: #273c75;
}
.blue {
  background: #0984e3;
}
.blue-text {
  color: #0984e3;
}
.dark-blue-text {
  color: #04044c;
}
.grey {
  background: #ddd;
}
.btn-custom {
  color: #fff;
  background: #0984e3;
  border-radius: 3px;
}
.red-text {
  color: #ca3424;
}
.list.flex-around {
  list-style: none;
  border-radius: 3px;
  border: #ccc 1px solid;
  margin: 2px;
  padding: 2% 0;
  transition: all 0.3s;
}
.list:hover {
  background: #444;
  color: #eee;
}
.list-item {
  font-size: 11px;
  font-weight: bold;
  padding: 0 3%;
}
.pad {
  width: 25%;
}
.grouped-item {
  width: 50%;
  display: inline-block;
}

/*  ACCOUNT CARD POPUP  */

.account-card {
  width: 15vw;
  z-index: 20;
  position: absolute;
  top: 100%;
  left: 50%;
  margin: 0;
  padding: 0;
  background: #fff;
  transition: opacity 0.5s;
  color: #333;
  border-radius: 3%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  padding: 0 2%;
}

.company-account-card {
  left: 60% !important;
}

.account-name {
  display: flex;
  align-items: center;
  padding: 5%;
}
.user-circle {
  padding: 5%;
  border-radius: 50%;
  margin-right: 2%;
  color: #fff;
  font-weight: bold;
  background: #444;
}
.account-card:after,
.account-card:before {
  bottom: 100%;
  left: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}
.account-card:after {
  border-color: rgba(136, 183, 213, 0);
  border-bottom-color: #fff;
  border-width: 12px;
  margin-left: -12px;
}
.account-card:before {
  border-color: rgba(194, 225, 245, 0);
  border-bottom-color: #333;
  border-width: 12px;
  margin-left: -12px;
}

@media (min-width: 1920px) and (max-width: 2560px) {
  .account-card {
    left: 33%;
  }

  .company-account-card {
    left: 41% !important;
  }
}
/*  Hover box */

.hover-box {
  width: 15%;
  background: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3), 0 8px 20px rgba(0, 0, 0, 0.2);
  padding: 1%;
  border-radius: 4%;
}
.selected-item {
  background: #ccc !important;
}
.completed-item {
  border-right: 5px solid #27ae60 !important;
}
.searchfield {
  width: 15%;
  position: absolute;
  top: 1px;
  right: 20px;
  border: 1px solid #aaa;
}

/*  DOCUMENT  */
.document-card {
  width: 30vw;
  display: inline-block;
}
.doc-container {
  display: block;
  z-index: 50;
  position: fixed;
  top: 0;
  right: 0;
  width: 100vw;
  min-height: 120vh;
  background: rgba(0, 0, 0, 0.5);
}
.doc-item {
  position: relative;
  display: flex;
  justify-content: center;
  border: #333 2px solid;
  top: 2vh;
  left: 20vw;
  width: 70vw;
  height: 96vh;
  background: #fff;
  overflow-y: scroll;
}

/*  NOTIFICATION  */

.notification-box {
  position: fixed;
  z-index: 2000;
  top: 10px;
  right: 10px;
  padding: 5px 15px;
  border-radius: 5px;
  border-top: 3px solid #b8e994;
  border-bottom: 3px solid #b8e994;
  background: #27ae60;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  animation: fade linear 5000ms;
}
@keyframes fade {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.notification {
  padding: 0;
  color: #b8e994;
}
.notification .material-icons {
  color: #44bd32;
  font-size: 3rem;
  vertical-align: middle;
}
.error-notification-box {
  position: fixed;
  z-index: 2000;
  top: 10px;
  right: 10px;
  padding: 5px 15px;
  border-radius: 5px;
  border-top: 3px solid #fa8231;
  border-bottom: 3px solid #fa8231;
  background: #c0392b;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  animation: fade linear 5000ms;
}
.error-notification {
  padding: 0;
  color: #fff;
}
.error-notification .material-icons {
  color: #fa8231;
  font-size: 3rem;
  vertical-align: middle;
}
.back-button {
  position: relative;
  margin-top: -15px;
  left: 15px;
  bottom: -10px;
  cursor: pointer;
}
.edit-button {
  position: absolute;
  top: 5px;
  right: 5px;
  color: #0054f3;
}
.cancel-button {
  position: absolute;
  top: 5px;
  right: 5px;
  color: #c00;
  z-index: 5;
  padding: 0;
}
.back-button:hover,
.edit-button:hover {
  color: #aaa;
}
.label-sm {
  font-size: 12px;
  margin: 0;
}
.error {
  color: #f00 !important;
  font-size: 11px !important;
}
.input-error {
  color: #f00 !important;
}

/*==================================================================
[ Fix header ]*/
.table100 {
  position: relative;
  padding-top: 40px;
}

.table100-head {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
}

.table100-body {
  max-height: 585px;
  overflow: auto;
}

/*==================================================================
[ Ver1 ]*/

.table100.ver1 th {
  font-size: 14px;
  font-weight: bold;
  color: #fff;
  line-height: 1.4;
  background-color: #6c7ae0;
}

.row100 .pointer {
  text-decoration: underline;
}

.table100.ver1 td {
  font-weight: bold;
  font-size: 12px;
  color: #808080;
  line-height: 1.4;
}

.table100.ver1 .table100-body tr:nth-child(even) {
  background-color: #f8f6ff;
}

.table100.ver1 {
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 0px 40px 0px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0 0px 40px 0px rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: 0 0px 40px 0px rgba(0, 0, 0, 0.15);
  -o-box-shadow: 0 0px 40px 0px rgba(0, 0, 0, 0.15);
  -ms-box-shadow: 0 0px 40px 0px rgba(0, 0, 0, 0.15);
}

.table100.ver1 .ps__rail-y {
  right: 5px;
}

.table100.ver1 .ps__rail-y::before {
  background-color: #ebebeb;
}

.table100.ver1 .ps__rail-y .ps__thumb-y::before {
  background-color: #cccccc;
}

/*  MEDIA QUERIES  */

@media (max-width: 600px) {
  /*  Navigation  */
  #navigation {
    text-align: center;
  }

  .carousel-image {
    height: 90vh;
  }
  .carousel-text-box-two {
    top: 15%;
    left: 15vw;
  }
  .carousel-left,
  .carousel-right {
    display: none;
  }
  .partner-row {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .partner-item {
    width: 80vw;
    height: 15vh;
    padding-top: 5%;
  }

  .contact-block {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .contact-block .map-item {
    width: 80vw;
    height: 50vh;
  }
  .contact-form {
    width: 80vw;
    text-align: center;
  }
  .colon {
    display: none;
  }
  .contact-input {
    margin-left: 5%;
  }
  .contact-button {
    margin: 5px;
  }
  .login-card {
    position: absolute;
    width: 80%;
    left: 10%;
    top: 10%;
  }
  .scale {
    margin-left: 0;
    transition: all 0.4s ease-out;
  }
  #sidebar {
    width: 60vw;
    padding-top: 10vh;
  }
  .hidden-item {
    display: none;
  }
}

@media (max-width: 800px) {
  /*  Navigation  */
  #navigation {
    text-align: center;
  }

  .carousel-image {
    height: 90vh;
  }
  .carousel-text-box-two {
    top: 15%;
    left: 15vw;
  }
  .carousel-left,
  .carousel-right {
    display: none;
  }
  .partner-row {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .partner-item {
    width: 80vw;
    height: 15vh;
    padding-top: 5%;
  }

  .career-card {
    height: 100%;
  }
  .overlay > h4 {
    font-size: 15px;
  }
  .overlay > p {
    font-size: 14px;
  }
  .contact-block {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .contact-block .map-item {
    width: 80vw;
    height: 50vh;
  }
  .contact-form {
    width: 80vw;
    text-align: center;
  }
  .colon {
    display: none;
  }
  .contact-input {
    margin-left: 5%;
  }
  .contact-button {
    margin: 5px;
  }
  .login-card {
    position: absolute;
    width: 80%;
    left: 10%;
    top: 10%;
  }
  .scale {
    margin-left: 0;
    transition: all 0.4s ease-out;
  }
  #sidebar {
    width: 40vw;
    padding-top: 5vh;
  }
}

/* News Ticker */
* {
  margin: 0;
  padding: 0;
}

.Newsticker {
  font-family: sans-serif;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.slider {
  background: #fff;
  width: 100%;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.4);
  border-radius: 5px;
  padding: 15px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.slider h4 {
  padding-bottom: 10px;
}

.slide-track-container {
  overflow: hidden;
  position: relative;
  height: 60vh;
}

@media (min-width: 1920px) and (max-width: 2560px) {
  .slide-track-container {
    height: 60vh;
  }
}

.slide-track {
  padding: 20px;
  position: absolute;
  backface-visibility: hidden;
  animation: slide 15s linear infinite;
  animation-iteration-count: infinite;
  animation-timing-function: cubic-bezier(1, 0, 5, 1);
  overflow: hidden;
  width: 90%;
  bottom: 0;
  left: 0;
  animation-play-state: running;
}

/* @keyframes slides {
  0% {
    bottom: -200%;
  }
  100% {
    bottom: 100%;
  }
} */

.slide-track:hover {
  animation-play-state: paused;
}

.slide-item {
  cursor: pointer;
  padding: 0.5rem;
  padding-right: 0px;
  padding-left: 10px;
  width: 150%;
  border-radius: 3px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  margin: 0.5rem;
  background: #058026;
  opacity: 0.8;
  color: white;
}

/* CareerForm */
#apply-Name,
#apply-Email,
#apply-Phone,
#apply-Select {
  width: 71%;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Login Link */
#loginLink {
  color: #0756ac;
}

/*
CSS for the Career component in home_page
*/
#carrerId {
  margin-top: -40px;
}
#carrerId2 {
  margin-top: -50px;
  margin-bottom: -20px;
}
#carrerId3 {
  margin-top: -20px;
}
#carrerId4 {
  padding-bottom: 39px;
  padding-top: -20px;
}
#carrerbtn {
  background: rgb(13, 162, 231);
}

/* mobile view resolution css */
@media only screen and (max-width: 600px) {
  .career-col-one,
  .career-col-two {
    padding-left: 0;
    padding-right: 0;
  }
}
/*
This css for the validation for career apply form in pgreacthome
*/
.careerError {
  color: #f00 !important;
  font-size: 11px !important;
  margin-left: 130px;
}

#product-pie {
  font-family: "Alex Brush", cursive !important;
  font-weight: bold;
}

#employee-login-h1 {
  text-align: center;
  font-family: "Alex Brush", cursive;
  font-size: 25px !important;
}
