.home__hero-section {
  color: #fff;
}

.home__hero-row {
  align-items: center;
}

.row {
  display: flex;
  margin-right: -15px;
  margin-bottom: -15px;
  flex-wrap: wrap;
  align-content: stretch;
}

.col {
  margin-bottom: 15px;
  padding-right: 15px;
  padding-left: 0;
  flex: 1;
  max-width: 60%;
  flex-basis: 60%;
}

.col-2 {
  margin-bottom: 15px;
  padding-left: 15px;
  padding-right: 0;
  flex: 1;
  max-width: 35%;
  flex-basis: 35%;
}

.home__hero-text-wrapper {
  max-width: 80%;
  padding-top: 0;
  padding-bottom: 60px;
  height: 475px;
}

.top-line {
  color: #058026;
  font-size: 16px;
  line-height: 16px;
  font-weight: 700;
  letter-spacing: 1.4px;
  text-transform: uppercase;
  margin-bottom: 35px;
}

.heading {
  margin-bottom: 35px;
  font-size: 40px;
  line-height: 1.1;
  font-weight: 600;
  color: #f7f8fa;
}

.dark {
  color: #1c2237;
}

.darkBg {
  background-color: #1c2237;
}

.home__hero-subtitle {
  max-width: 100%;
  margin-bottom: 35px;
  font-size: 18px;
  line-height: 24px;
  text-align: justify;
}

.home__hero-img-wrapper {
  display: inline-flex;
}

.home__hero-img {
  max-width: 100%;
  margin-top: 0;
  margin-right: 0;
  padding-right: 0;
}

img {
  border: 0;
  max-width: 100%;
  vertical-align: middle;
  display: inline-block;
}

@keyframes zoominoutsinglefeatured {
  0% {
    transform: scale(0, 0);
  }
  50% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(1.2, 1.2);
  }
}

.home__hero-img-wrapper img {
  animation: zoominoutsinglefeatured 5s forwards;
}

.home_hero_btn_link {
  text-align: center;
}

@media screen and (max-width: 991px) {
  .container {
    padding-right: 30px;
    padding-left: 30px;
  }
}

@media screen and (max-width: 768px) {
  .home__hero-text-wrapper {
    padding-bottom: 65px;
    max-width: 100%;
    height: 684px;
  }

  .col {
    max-width: 100%;
    flex-basis: 100%;
    padding-right: 0;
  }

  .col-2 {
    max-width: 100%;
    flex-basis: 100%;
    padding-left: 0;
  }
}
